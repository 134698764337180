import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

// import { HelmetDatoCms } from 'gatsby-source-datocms'

import { Container, Row, Col } from "reactstrap"

import Layout from "../components/layout"

import styled from "styled-components"

let PostHeading = styled.div`
  padding: 100px 0 75px;  
  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }  
`

let H1 = styled.h1`
  font-size: 35px;  
  @media only screen and (min-width: 768px) {    
    font-size: 55px;
  }
`

let SubHeading = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.1;
  display: block;
  margin: 10px 0 30px;
  @include sans-serif-font;
  @media only screen and (min-width: 768px) {    
    font-size: 30px;
  }
`
let PostMeta = styled.span`
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  @include serif-font;
`

const BlogPostTemplate = ({ data, location }) => {
  const post = data.datoCmsBlog  
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  const body = []
  let i = 0  

  for(let element of post.body) {
    i+=1
    if(element.contentNode) {
      body.push(
        <Row key={i}>
          <Col className="col-lg-8 col-md-10 mx-auto" dangerouslySetInnerHTML={{ __html: element.contentNode.childMarkdownRemark.html }} />
        </Row> 
      );
    } else if (element.image) {
      body.push(
        <Row key={i} className="justify-content-center">
          <Col sm="8" className="align-self-center">
            <figure className="rounded p-3 bg-white shadow-sm">
              <Img fluid={element.image.fluid} className="img-thumbnail"/>              
              {element.caption2Node && element.caption2Node.childMarkdownRemark &&
                <figcaption className="p-4 card-img-bottom lead" dangerouslySetInnerHTML={{ __html: element.caption2Node.childMarkdownRemark.html }}>                                                                            
                </figcaption>
              }              
            </figure>            
          </Col>
        </Row>
      )
    } else if (element.photo && element.photo.length > 0) {
      const gallery = []
      for(let u of element.photo) {        
        gallery.push(<Col className="col-md-6 col-lg-4"><Img fluid={u.fluid} className="img-thumbnail"/></Col>)
      }
      body.push(<Row key={i} className="py-2">{gallery}</Row>)
    } 
  }

  post.seoMetaTags.tags[0].content = post.title + " | Indus Chadha"

  return (
    <Layout location={location} seo={post.seoMetaTags}>
      <Container>
        <PostHeading>
            <H1>{post.title}</H1>
            <SubHeading>{post.subTitle}</SubHeading>
            <PostMeta>{post.publishDate}</PostMeta>
        </PostHeading>
      </Container>

      <article>
        <Container> 
          {body}                                    
        </Container>
      </article>

      <Container className="pt-5" data-datocms-noindex>
        <Row>
          <Col className="col-lg-8 col-md-10 mx-auto">
            <nav className="blog-post-nav">
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link to={"/" + previous.slug} rel="prev">
                        ← {previous.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={"/" + next.slug} rel="next">
                        {next.title} →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {      
    site {
      siteMetadata {
        title
      }
    }  
    datoCmsBlog(id: { eq: $id }) {      
      id
      title
      subTitle
      subTitleNode {
        childMarkdownRemark{
          html
        }
      }
      slug
      publishDate(formatString: "MMMM DD, YYYY")
      body {
        ... on DatoCmsArticle {
          id
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsQuote {
          id
          author
          quote
        }
        ... on DatoCmsImage {
          id
          image {
            fluid {
              ...GatsbyDatoCmsSizes
            }            
          }
          caption2Node {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsGallery {
          id
          photo {
            fluid {            
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    previous: datoCmsBlog(id: { eq: $previousPostId }) {
      title
      slug
    }
    next: datoCmsBlog(id: { eq: $nextPostId }) {
      title
      slug
    }
  }
`
